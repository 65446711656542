<template>
	<div class="app-container">
		<el-tabs
			v-model="activeTab"
			@tab-click="handleClick"
			v-loading="loading"
		>
			<el-tab-pane label="Clan Log" name="clanlog">
				<el-row>
					<el-col :span="3">
							<el-select
								v-model="currentOption"
								placeholder="Select"
							>
								<el-option
									v-for="item in ldbNameOptions"
									:key="item"
									:label="item"
									:value="item"
								>
								</el-option>
							</el-select>
					</el-col>
					<el-col :span="2">
						<el-button
							type="primary"
							icon="el-icon-search"
							@click="exportClanLDBLog()"
							>Export Log</el-button
						>
					</el-col>
				</el-row>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
import { SERVER_RESPONSE_CODE, CLAN_STATUS } from "@/defines/constants.js";
import apiClanLDB from "@/api/apiClanLDB.js";
import setting from "@/settings.js";

export default {
	name: "ClanLDBLog",
	data() {
		return {
			loading: false,
			activeCollapseName: ["clanlog"],
			activeTab: "clanlog",
			allClanLDBSettings: [],
			currentOption: "",
			ldbNameOptions: [],
		};
	},
	async created() {
		this.loading = false;
		await this.getAndLoadAllClanLDBSetting();
	},

	methods: {
		// ========================================
		async getAndLoadAllClanLDBSetting() {
			try {
				this.loading = true;
				let returnData = await apiClanLDB.getAllClanLDBSettings();
				this.loading = false;
				if (returnData !== null &&
					returnData !== undefined &&
					returnData.code !== null &&
					returnData.code !== undefined &&
					returnData.code === SERVER_RESPONSE_CODE.SUCCESS) {

					this.ldbNameOptions = [];
					this.allClanLDBSettings = returnData.data;
					this.allClanLDBSettings.forEach((clanSetting) => {
						console.log(clanSetting.ldbGroupName);
						let timeNow = Math.floor(Date.now() / 1000);
						if(clanSetting.endTimestamp < timeNow) {
							this.ldbNameOptions.push(clanSetting.ldbGroupName);
						}
					});
				}
			}
			catch (error) {
				this.loading = false;
				console.log(error);
			}
		},

		// ========================================
		async exportClanLDBLog() {
			try {
				if (this.currentOption.length <= 0) {
					Message({
						message: `Please select "Export" Options`,
						type: "error",
						duration: 5 * 1000,
					});
					return;
				}
					
				this.loading = true;
				let returnData = await apiClanLDB.getClanLDBLog(this.currentOption);
				this.convertReturnDataToFile(returnData);
				
				this.loading = false;

			} catch (error) {
				this.loading = false;
				console.log(error);
			}
		},

		// ========================================
		convertReturnDataToFile(returnData) {
			if(returnData.type === 'application/json') {
				Message({
						message: 'Khong co log ldb clan',
						type: "error",
						duration: 5 * 1000,
					});
				return;
			}

			var fileURL = window.URL.createObjectURL(new Blob([returnData], {type: 'application/octet-stream'}));
			console.log(fileURL);
			var fileLink = document.createElement('a');
			fileLink.href = fileURL;
			fileLink.setAttribute('download', `${this.currentOption}.zip`);
			document.body.appendChild(fileLink);
			fileLink.click();
		},

		// ========================================
		// OTHERS METHOD
		async handleClick(tab, event) {
			if (tab.name === "clanlog") {
				console.log('xxx');
			}
		},
	},
};
</script>